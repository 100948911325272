<template>
  <div>
    <ac-module-heading> Statements </ac-module-heading>
    <v-card class="card-wrapper">
      <v-card-text>
        <div class="d-flex align-center flex-wrap pb-0">
          <div class="d-flex align-center pb-5">
            <ac-select
              label="Company"
              v-model="selectedCompany"
              :items="companies"
              class="company-select me-3"
            />
            <ac-button v-if="currentTabIndex === 0" title="Add" :icon="icons.mdiPlus" />
          </div>
          <v-spacer />
          <div class="d-flex align-center pb-5">
            <ac-button
              v-if="currentTabIndex === 1"
              title="Download CSV"
              class="me-3"
              :icon="icons.mdiDownloadOutline"
            />
            <ac-input
              v-model="searchQuery"
              placeholder="Search"
              outlined
              class="staff-list-search me-3"
            />
          </div>
        </div>
        <div class="d-flex align-center flex-wrap pb-0">
          <ac-select
            label="User"
            v-model="selectedUser"
            :items="userOPtions"
            class="company-select me-3"
          />
          <ac-select
            label="Year"
            v-model="selectedYear"
            :items="yearOPtions"
            class="company-select me-3"
          />
          <v-spacer />
        </div>
      </v-card-text>
      <ac-tabs :tabs="tabs" v-model="currentTabIndex" @input="onTabChange">
        <template #all-statements>
          <v-data-table
            :headers="statementsHeaders"
            :items="allStatements"
            :options.sync="statementsOptions"
            :server-items-length="totalStatements"
            :loading="loading"
            class="text-no-wrap"
          >
            <template #[`item.broker`]="{ item: { broker } }">
              <span>{{ broker && broker.broker ? broker.broker : '' }}</span>
            </template>

            <template #[`item.actions`]>
              <ac-icon-btn text="Remove" color="error" :icon="icons.mdiDeleteOutline" />
            </template>
          </v-data-table>
        </template>
        <template #broker-statements>
          <v-data-table
            :headers="brokerStatementsHeaders"
            :items="brokerStatements"
            :options.sync="brokerStatementsOptions"
            :server-items-length="totalBrokerStatements"
            :loading="loading"
            class="text-no-wrap"
          >
            <template #[`item.jan`]="{ item }">
              <span :class="monthValue(1, item).value.class ? 'error--text' : ''">
                {{ monthValue(1, item).value.text }}
              </span>
            </template>

            <template #[`item.feb`]="{ item }">
              <span :class="monthValue(2, item).value.class ? 'error--text' : ''">
                {{ monthValue(2, item).value.text }}
              </span>
            </template>

            <template #[`item.mar`]="{ item }">
              <span :class="monthValue(3, item).value.class ? 'error--text' : ''">
                {{ monthValue(3, item).value.text }}
              </span>
            </template>

            <template #[`item.apr`]="{ item }">
              <span :class="monthValue(4, item).value.class ? 'error--text' : ''">
                {{ monthValue(4, item).value.text }}
              </span>
            </template>

            <template #[`item.may`]="{ item }">
              <span :class="monthValue(5, item).value.class ? 'error--text' : ''">
                {{ monthValue(5, item).value.text }}
              </span>
            </template>

            <template #[`item.jun`]="{ item }">
              <span :class="monthValue(6, item).value.class ? 'error--text' : ''">
                {{ monthValue(6, item).value.text }}
              </span>
            </template>

            <template #[`item.jul`]="{ item }">
              <span :class="monthValue(7, item).value.class ? 'error--text' : ''">
                {{ monthValue(7, item).value.text }}
              </span>
            </template>

            <template #[`item.aug`]="{ item }">
              <span :class="monthValue(8, item).value.class ? 'error--text' : ''">
                {{ monthValue(8, item).value.text }}
              </span>
            </template>

            <template #[`item.sep`]="{ item }">
              <span :class="monthValue(9, item).value.class ? 'error--text' : ''">
                {{ monthValue(9, item).value.text }}
              </span>
            </template>

            <template #[`item.oct`]="{ item }">
              <span :class="monthValue(10, item).value.class ? 'error--text' : ''">
                {{ monthValue(10, item).value.text }}
              </span>
            </template>

            <template #[`item.nov`]="{ item }">
              <span :class="monthValue(11, item).value.class ? 'error--text' : ''">
                {{ monthValue(11, item).value.text }}
              </span>
            </template>

            <template #[`item.dec`]="{ item }">
              <span :class="monthValue(12, item).value.class ? 'error--text' : ''">
                {{ monthValue(12, item).value.text }}
              </span>
            </template>
          </v-data-table>
        </template>
      </ac-tabs>
    </v-card>
  </div>
</template>

<script>
import AcButton from '@/components/AcButton.vue'
import AcInput from '@/components/AcInput.vue'
import AcSelect from '@/components/AcSelect.vue'
import AcTabs from '@/components/AcTabs.vue'
import AcIconBtn from '@/components/AcIconBtn.vue'
import store from '@/store'
import padealingStoreModule from '@/store/modules/pa-dealing'
import {
  mdiBellOffOutline,
  mdiBellOutline,
  mdiCheckBold,
  mdiCloseThick,
  mdiDeleteOutline,
  mdiDownloadOutline,
  mdiEyeOutline,
  mdiPlus,
  mdiSend,
} from '@mdi/js'
import { onUnmounted } from '@vue/composition-api'
import useStatements from './composition-apis/statements'

export default {
  components: {
    AcButton,
    AcSelect,
    AcInput,
    AcTabs,
    AcIconBtn,
  },
  setup() {
    const PA_DEALING_STORE_MODULE_NAME = 'pa-dealing'

    // Register module
    if (!store.hasModule(PA_DEALING_STORE_MODULE_NAME)) {
      store.registerModule(PA_DEALING_STORE_MODULE_NAME, padealingStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PA_DEALING_STORE_MODULE_NAME))
        store.unregisterModule(PA_DEALING_STORE_MODULE_NAME)
    })

    const tabs = [
      { title: 'All Statements', content: 'all-statements' },
      { title: 'Broker Statements', content: 'broker-statements' },
    ]

    const {
      statementsHeaders,
      statementsOptions,
      allStatements,
      totalStatements,
      brokerStatementsHeaders,
      brokerStatementsOptions,
      brokerStatements,
      totalBrokerStatements,
      currentTabIndex,
      searchQuery,
      companies,
      selectedCompany,
      loading,
      userOPtions,
      selectedUser,
      yearOPtions,
      selectedYear,
      monthValue,
      onTabChange,
    } = useStatements()

    return {
      tabs,
      statementsHeaders,
      statementsOptions,
      allStatements,
      totalStatements,
      brokerStatementsHeaders,
      brokerStatementsOptions,
      brokerStatements,
      totalBrokerStatements,
      currentTabIndex,
      searchQuery,
      companies,
      selectedCompany,
      loading,
      userOPtions,
      selectedUser,
      yearOPtions,
      selectedYear,
      monthValue,
      onTabChange,

      icons: {
        mdiDownloadOutline,
        mdiBellOffOutline,
        mdiBellOutline,
        mdiCheckBold,
        mdiCloseThick,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiPlus,
        mdiSend,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  .company-select {
    max-width: 16rem;
  }
  .staff-list-search {
    max-width: 12rem;
  }
}
</style>
