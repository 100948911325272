import store from '@/store'
import { computed, ref, watch } from '@vue/composition-api'
import moment from 'moment-timezone'

export default function useStatements() {
  const unfilteredAllStatements = ref([])
  const totalStatements = ref(0)
  const unfilteredBrokerStatements = ref([])
  const totalBrokerStatements = ref(0)
  const loading = ref(false)
  const searchQuery = ref('')
  const companies = ref(['Company 1', 'Company 2', 'Company 3'])
  const selectedCompany = ref('')
  const selectedYear = ref('')
  const selectedUser = ref('')
  const currentTabIndex = ref(0)

  const statementsHeaders = [
    { text: 'User', value: 'name' },
    { text: 'Broker', value: 'broker' },
    { text: 'Date', value: 'date' },
    { text: 'Alert', value: 'alert' },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]
  const brokerStatementsHeaders = [
    { text: 'User', value: 'userName' },
    { text: 'Broker', value: 'broker' },
    { text: 'Account Number', value: 'accountNumber' },
    { text: 'Active Since', value: 'activeSince' },
    { text: 'Jan', value: 'jan' },
    { text: 'Feb', value: 'feb' },
    { text: 'Mar', value: 'mar' },
    { text: 'Apr', value: 'apr' },
    { text: 'May', value: 'may' },
    { text: 'Jun', value: 'jun' },
    { text: 'Jul', value: 'jul' },
    { text: 'Aug', value: 'aug' },
    { text: 'Sep', value: 'sep' },
    { text: 'Oct', value: 'oct' },
    { text: 'Nov', value: 'nov' },
    { text: 'Dec', value: 'dec' },
  ]

  const statementsOptions = ref({})
  const brokerStatementsOptions = ref({})

  const fetchAllStatements = () => {
    store
      .dispatch('pa-dealing/fetchAllStatements', {
        q: searchQuery.value,
        options: statementsOptions.value,
      })
      .then(data => {
        unfilteredAllStatements.value = data.docs
        totalStatements.value = data.totalItems

        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  const fetchBrokerStatements = () => {
    store
      .dispatch('pa-dealing/fetchBrokerStatements', {
        q: searchQuery.value,
        options: brokerStatementsOptions.value,
      })
      .then(data => {
        unfilteredBrokerStatements.value = data.docs
        totalBrokerStatements.value = data.totalItems

        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, statementsOptions, brokerStatementsOptions], () => {
    loading.value = true
    fetchAllStatements()
    fetchBrokerStatements()
  })

  // *====================---*
  // *------- UI -------------------*
  // *====================---*

  const allStatements = computed(() => {
    let statements = []
    if (!selectedUser.value) {
      statements = unfilteredAllStatements.value
    } else {
      statements = unfilteredAllStatements.value.filter(val => val.name === selectedUser.value)
    }

    if (selectedYear.value && selectedYear.value !== 'All') {
      statements = statements.filter(val => moment(val.date, 'DD MMM YYYY').format('YYYY') === selectedYear.value)
    }
    return statements
  })

  const brokerStatements = computed(() => {
    if (!selectedUser.value) {
      return unfilteredBrokerStatements.value
    }
    return unfilteredBrokerStatements.value.filter(val => val.userName === selectedUser.value)
  })

  const userOPtions = computed(() => {
    if (currentTabIndex.value === 0) {
      return unfilteredAllStatements.value.map(val => val.name)
    } else if (currentTabIndex.value === 1) {
      return unfilteredBrokerStatements.value.map(val => val.userName)
    }
    return []
  })

  const yearOPtions = computed(() => {
    let options = []
    if (currentTabIndex.value === 0 && unfilteredAllStatements.value.length) {
      options.push('All')
      options = options.concat(unfilteredAllStatements.value.map(val => moment(val.date, 'DD MMM YYYY').format('YYYY')))
    } else if (currentTabIndex.value === 1 && unfilteredBrokerStatements.value.length) {
      const keys = Object.keys(unfilteredBrokerStatements.value[0])
      options = options.concat(keys.filter(key => key.length === 4 && !isNaN(key)))
    }

    return options
  })

  const monthValue = (month, value) => {
    if (selectedYear.value) {
      const year = selectedYear.value
      return value[year] && value[year][month] ? { value: value[year][month] } : { value: { text: '', class: '' } }
    }
    return { value: { text: '', class: '' } }
  }

  const onTabChange = () => {
    selectedUser.value = undefined
    selectedYear.value = undefined
  }

  return {
    statementsHeaders,
    statementsOptions,
    allStatements,
    totalStatements,
    brokerStatementsHeaders,
    brokerStatementsOptions,
    brokerStatements,
    totalBrokerStatements,
    currentTabIndex,
    companies,
    selectedCompany,
    searchQuery,
    loading,
    userOPtions,
    selectedUser,
    yearOPtions,
    selectedYear,
    monthValue,
    onTabChange,
  }
}
